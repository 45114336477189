table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.table-container {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.report-table {
    border-radius: 10%;
    margin: 10px 0px;
    color: white;
    background-color: green;
}

table td,
table th {
    border: 1px solid #ddd;
    padding: 8px;
}

table tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* table tr:hover {
    background-color: #ddd;
} */

table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center !important;
    background-color: transparent;
}

.pagination {
    display: flex;
    justify-content: center;
}

.invoice--search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.invoice--search__input {
    width: 400px;
    border: 0.5px ridge gray;
    border-radius: 3px;
    height: 40px;
}

.invoice--search__csv-button {
    color: green;
}

.pagination > button,
.pagination > span,
.pagination > select {
    margin: 10px 5px;
}

.date-filter {
    color: green;
    width: 190px;
}
