.bread {
    margin-left: 20%;
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    /* Go big on the size, and let overflow hide */
    border-bottom: 50px solid transparent;
    border-left: 30px solid #d3d9e5;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    /* z-index: 2;  */
}

.bread.active {
    border-left: 30px solid #5d9f2d;
}

.navs li a {
    padding: 5px 10px !important;
}

.navs li a {
    padding: 5px 10px !important;
}

.navs li a.active {
    border-bottom-color: #5d9f2d !important;
    color: #fff !important;
    background-color: #5d9f2d !important;
}

.navs li a.active:hover {
    background-color: #5d9f2d !important;
}

.navs li a:hover {
    background-color: transparent !important;
}

.cardMain {
    background-color: #d3d9e5;
    color: #747d8c;
}

#progressbar {
    margin: 10px 30px;
    overflow: hidden;
    color: #c0c7da;
}

#progressbar .active h2 {
    color: #000000;
    font-weight: 500;
}

#progressbar li {
    list-style-type: none;
    font-size: 16px;
    width: 19.5%;
    float: left;
    position: relative;
    font-weight: 500;
}

#progressbar li h2 {
    font-size: 16px !important;
    position: relative;
    font-weight: 300;
    text-align: center;
    color: #bbb;
}

#progressbar #pgdetail:before {
    font-family: FontAwesome;
    content: '\f023';
}

#progressbar #roomdetail:before {
    font-family: FontAwesome;
    content: '\f007';
}

#progressbar #facilities:before {
    font-family: FontAwesome;
    content: '\f09d';
}

#progressbar #services:before {
    font-family: FontAwesome;
    content: '\f00c';
}

#progressbar #offer:before {
    font-family: FontAwesome;
    content: '\f00c';
}

#progressbar #confirm:before {
    font-family: FontAwesome;
    content: '\f00c';
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 18px;
    color: #ffffff;
    background: #ececec;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px;
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: #ececec;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #ff3a59;
}

.radio-group {
    position: relative;
    margin-bottom: 25px;
}

body {
    margin-top: 20px;
}

.timeline-steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.timeline-steps .timeline-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 1rem;
}

@media (min-width: 768px) {
    .timeline-steps .timeline-step:not(:last-child):after {
        content: '';
        display: block;
        border-top: 0.25rem dotted #5d9f2d;
        width: 3.46rem;
        position: absolute;
        left: 7.5rem;
        top: 0.3125rem;
    }

    .timeline-steps .timeline-step:not(:first-child):before {
        content: '';
        display: block;
        border-top: 0.25rem dotted #5d9f2d;
        width: 3.8125rem;
        position: absolute;
        right: 7.5rem;
        top: 0.3125rem;
    }
}

.timeline-steps .timeline-content {
    width: 10rem;
    text-align: center;
}

.timeline-steps .timeline-content .inner-circle {
    border-radius: 1.5rem;
    height: 1rem;
    width: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #5d9f2d;
}

.timeline-steps .timeline-content .inner-circle:before {
    content: '';
    background-color: #5d9f2d;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    min-width: 3rem;
    border-radius: 6.25rem;
    opacity: 0.5;
}

.dashmap > svg {
    position: relative !important;
    width: 350px !important;
}

.map-title {
    color: white;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: white !important;
}

.muifilter {
    width: 156px;
    background: transparent;
    padding: 8px;
    border: none;
    color: white;
}

.card-title {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.2;
    /* color: #e6e9ec; */
}

.MuiTabs-flexContainer {
    background: #1d4164 !important;
}

.react-datepicker-wrapper > input {
    padding: 10px;
}

.datepicker {
    padding: 10px;
    background: transparent;
    border: none;
    color: white;
}

.cart-header {
    color: #e6e9ec !important;
}

.filtertab {
    color: white !important;
}

.filters {
    display: flex;
    justify-content: space-evenly;
    background: rgb(29, 65, 100);
    margin: 65px 0px;
    box-shadow: -2px -1px 20px -5px rgb(94 85 85 / 75%);
}
