.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

    .form-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px; /* Adjust the gap as needed */
   
}
.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
.css-rnmm7m-MuiPaper-root-MuiDialog-paper{
    background-color: none !important;
}
.App-link {
    color: #61dafb;
}
.assigncancel{
    display: flex;
    justify-content: space-between;
    padding: 16px;
}
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.htmldescription li{
    list-style-type: disc !important;
}
.noAdmin {
    background: radial-gradient(
            circle at 39% 47%,
            rgba(107, 107, 107, 0.08) 0%,
            rgba(107, 107, 107, 0.08) 33.333%,
            rgba(72, 72, 72, 0.08) 33.333%,
            rgba(72, 72, 72, 0.08) 66.666%,
            rgba(36, 36, 36, 0.08) 66.666%,
            rgba(36, 36, 36, 0.08) 99.999%
        ),
        radial-gradient(
            circle at 53% 74%,
            rgba(182, 182, 182, 0.08) 0%,
            rgba(182, 182, 182, 0.08) 33.333%,
            rgba(202, 202, 202, 0.08) 33.333%,
            rgba(202, 202, 202, 0.08) 66.666%,
            rgba(221, 221, 221, 0.08) 66.666%,
            rgba(221, 221, 221, 0.08) 99.999%
        ),
        radial-gradient(
            circle at 14% 98%,
            rgba(184, 184, 184, 0.08) 0%,
            rgba(184, 184, 184, 0.08) 33.333%,
            rgba(96, 96, 96, 0.08) 33.333%,
            rgba(96, 96, 96, 0.08) 66.666%,
            rgba(7, 7, 7, 0.08) 66.666%,
            rgba(7, 7, 7, 0.08) 99.999%
        ),
        linear-gradient(45deg, #478718, #84d34b);
}

.productList {
    display: grid;
    grid-template-columns: repeat(3, 350px);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
}

@media screen and (max-width: 1270px) {
    .productList {
        grid-template-columns: repeat(2, 350px);
    }
}

.mapaddress {
    max-width: 700px;
    justify-content: space-between;
    padding: 20px;
    margin-right: 12px;
    display: grid;
    grid-template-columns: 250px 250px;
}

@media screen and (max-width: 700px) {
    .productList {
        grid-template-columns: repeat(1, 350px);
    }
}

.dash-title-card {
    font-weight: 100;
}

.addremove {
    width: 31px;
    border-radius: 59px;
    background-color: #5b9e2b;
    color: white;
    font-size: 22px;
    text-align: center;
    cursor: pointer;
}

.cartButton {
    display: flex;
    justify-content: center;
    margin: 9px;
}

.productcard {
    margin: 0.9em;
    height: 10em;
    max-width: 25em;
    flex: 1 1 auto;
    display: flex;
    background-color: white;
    box-shadow: -1px 2px 16px 3px rgb(0 27 34 / 10%);
    border-radius: 15px;
    padding: 3px;
}
.productList_dropdown{
    z-index: 1000000;
}
.productcard img {
    max-width: 10.5em;
    -o-object-fit: cover;
    object-fit: cover;
    background-size: cover;
    flex: 1 1 auto;
    border-radius: 15px;
    width: 80px;
    margin-right: 16px;
    height: 100px;
}

.productcard-body {
    width: 12em;
    max-height: 100%;
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    /* padding: 1.8em; */
}

.productcard-text {
    position: relative;
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(0, 0, 0);
    padding: 2px 0;
    margin-left: 12px;
}

.productstock {
    color: grey;
    font-size: 11px;
}

.productcard-text h3,
.productcard-text p {
    margin-top: 0;
}

.productcard-text:after {
    position: absolute;
    bottom: 0;
    content: '';
    width: 100%;
    height: 2.8em;
    /* background: linear-gradient(rgba(255, 255, 255, 0), white); */
}

a {
    color: #d07777;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@media (max-width: 1100px) {
    .productcard {
        margin: 0.7826086957em;
    }

    .productcard img {
        max-width: 9em;
    }
}

@media (max-width: 870px) {
    .productcard {
        max-width: 100%;
    }
}

@media (max-width: 570px) {
    .container {
        flex-flow: column nowrap;
        padding: 0;
    }

    .productcard {
        margin: 0.75em 1.1em;
        height: 15em;
        flex: 1 1 auto;
        display: flex;
        background-color: white;
        box-shadow: 1px 3px 3px rgba(0, 10, 20, 0.06);
    }

    .productcard img {
        max-width: 45%;
    }
}

.cartItem {
    display: flex;
    justify-content: space-between;
    font-family: Arial, Helvetica, sans-serif;
    border-bottom: 1px solid lightblue;
    padding-bottom: 20px;
    margin-bottom: 30px;
}

.subdrop {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
}

.sidebar-menu a {
    text-decoration: none;
}

.subdrop a::before {
    width: 5px;
    content: '';
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #ffffff;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
}

.information,
.buttons {
    display: flex;
    justify-content: space-between;
}

.cartItemimg {
    max-width: 80px;
    object-fit: cover;
}

.information {
    width: 300px;
}

.productDetails {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding: 5px;
}

.cartItemButton {
    border-radius: 35px !important;
    height: 25px;
    width: 25px;
    min-width: 0 !important;
}

.productTable {
    display: flex;
    justify-content: space-between;
}

.productRow {
    font-size: 11px;
    font-weight: 700;
    color: grey;
}

.productName {
    font-size: 11px;
    color: green;
    padding: 7px;
}

.invoiceAdd {
    display: flex;
    justify-content: space-between;
    padding: 27px;
}

#invoice-top {
    border-bottom: 2px solid #00a63f;
    display: flex;
    justify-content: space-between;
    padding: 27px;
}

.cart {
    position: absolute;
    margin-top: 150px;
    width: 250px;
}

.stand {
    width: 20px;
    height: 150px;
    background: #880e4f;
    transform-origin: 0% 0%;
    transform: rotate(-20deg);
    position: absolute;
    z-index: 3;
}

.stand:before {
    width: 20px;
    height: 100px;
    background: #880e4f;
    margin-top: 150px;
    transform-origin: 100% 0%;
    transform: rotatez(50deg);
    border-radius: 0 0 60% 20%;
}

.stand:after {
    width: 14px;
    height: 55px;
    background: #880e4f;
    margin: 150px 6px;
    border-radius: 0 0 60% 20%;
}

.skate {
    position: absolute;
    height: 25px;
    width: 200px;
    background: orangered;
    border-radius: 50% 5% 55% 5%;
    z-index: 2;
    margin: 188px 15px;
}

.wheels {
    position: absolute;
    z-index: 2;
    width: 220px;
    height: 50px;
    margin-top: 180px;
}

.wheel {
    border-radius: 50%;
    background: #212121;
    border: 5px solid grey;
}

.wheel:nth-child(1) {
    width: 30px;
    height: 30px;
    margin: 10px -8px;
}

.wheel:nth-child(2) {
    width: 20px;
    height: 20px;
    margin: -45px 185px;
}

.wheel:nth-child(2):before {
    width: 35px;
    height: 25px;
    border-top: 6px solid white;
    margin: -11px -8px;
    border-radius: 50%;
}

.basket {
    width: 190px;
    height: 120px;
    border-radius: 0 100px 50px 100px/0 30px 70px 100px;
    background: transparent;
    position: absolute;
    margin-left: 20px;
    border: 4px solid yellow;
    border-bottom-width: 10px;
}

.basket:before {
    width: 250px;
    height: 20px;
    border-radius: 20px 30% 60% 20px;
    background: #bf360c;
    margin: -18px -50px;
    transform-origin: 0% 50%;
    transform: rotate(5deg);
    z-index: 5;
}

.rec-v {
    width: 20px;
    height: 100px;
    border-radius: 10px;
    border: 4px solid white;
    transform-origin: 50% 0%;
    transform: rotatez(-10deg);
    position: absolute;
}

.rec-v:nth-child(1) {
    margin: 5px 10px;
}

.rec-v:nth-child(2) {
    margin: 3px 55px;
    transform: rotatez(-5deg) scaley(1.12);
}

.rec-v:nth-child(3) {
    margin: 7px 105px;
    transform: rotatez(0deg) scaley(1.1);
}

.rec-v:nth-child(4) {
    margin: 4px 155px;
    transform: rotatez(5deg) scaley(1.1);
}

.rec-h {
    width: 180px;
    height: 25px;
    border-radius: 15px;
    border: 4px solid skyblue;
    transform-origin: 0% 50%;
    transform: rotate(5deg);
    margin: 15px 5px;
}

.rec-h2 {
    transform: rotate(5deg) scalex(0.9);
    margin: 15px;
}

.dashboard_cards {
    display: grid;
    justify-content: space-between;
    margin: 70px 0;
    grid-template-columns: repeat(4, 210px);    max-width: 1200px;
    grid-row-gap: 55px;
    padding: 0 28px;
}

.dashboard_card {
    width: 270px;
    height: 89px;
    border-radius: 6px;
    box-shadow: 0px -2px 16px -1px rgb(0 0 0 / 28%);
}

.bread > .active {
    display: none;
}

.dash-title-card {
    color: wheat;
}

.homebg {
    background-color: #082746f0;
    padding: 15px;
}
.variantdialog div:nth-child(4) {
     border-radius: 15px; 
}
.cardhome {
    background-color: #001e3c;
    color: white;
}
.variantlist{
    width: 500px;
    display: flex;
    justify-content: space-around;
    margin-bottom:20px;
    border-bottom: 1px solid #d0d0d3;
}
.productcardvaraint{
    display: flex;
    width: 500px;
    justify-content: space-between;
    margin-bottom: 48px;
}
.variantPrice{
    margin-top: 10px;
    font-weight: 400;
    font-size:20px ;
}
.chooseSize{
    font-size: 17px;
    padding: 10px;
    margin-bottom: 24px;}

  