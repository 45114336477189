.upload-btn-wrapper {
    text-align: left;
    margin-top: 10px;
}

.up_btn {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    padding: 12px ​18px 8px;
    padding: 12px 18px 8px;
    font-weight: 600;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    box-shadow: 0 1px 3px rgb(0 0 0 / 10%), 0 1px 2px rgb(0 0 0 / 18%);
    color: #212529;
    font-size: 13px;
}

.upload-btn-wrapper input[type='file'] {
    font-size: 12px;
    position: absolute;
    left: 0;
    opacity: 0;
    padding-top: 37px;
    cursor: pointer;
}

/* Agri Machine */
.driverPhoto {
    flex: 1;
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    padding: '20px';
    border-width: 2;
    border-radius: 2;
    border-color: '#eeeeee';
    border-style: 'dashed';
    background-color: '#fafafa';
    color: '#bdbdbd';
    outline: 'none';
    transition: 'border .24s ease-in-out';
    margin-bottom: '15px';
    margin-top: '15px';
}
