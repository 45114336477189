.orders-tab {
    height: 80px;
}
.orders-tab > .nav-item > a {
    color: rgb(43, 43, 43) !important;
    height: 100% !important;
    padding: 0.5rem 0.8rem !important;
    display: flex;
    flex-direction: column !important;
    justify-content: left !important;
    align-items: center !important;
}

.orders-tab > .activeOrders {
    background-color: #cde4f5 !important;
}
.orders-tab > .activeOrders .active {
    border-bottom: 3px solid #3097e7 !important;
}

.orders-tab > .pending {
    background-color: #b3dcf2 !important;
}
.orders-tab > .pending .active {
    border-bottom: 3px solid #22a9f2 !important;
}

.orders-tab > .accepted {
    background-color: #a9cce3 !important;
    color: #0074c1 !important;
}
.orders-tab > .accepted .active {
    border-bottom: 3px solid #0074c1 !important;
}

.orders-tab > .picked {
    background-color: #a9bfd8 !important;
}
.orders-tab > .picked .active {
    border-bottom: 3px solid #004a9d !important;
}

.orders-tab > .ready {
    background-color: #f4cfaa !important;
}
.orders-tab > .ready .active {
    border-bottom: 3px solid #fb7e02 !important;
}

.orders-tab > .completed {
    background-color: #bae1bd !important;
}
.orders-tab > .completed .active {
    border-bottom: 3px solid #3ab943 !important;
}

.orders-tab > .cancelled {
    background-color: #f3bdc1 !important;
}
.orders-tab > .cancelled .active {
    border-bottom: 3px solid #f7424f !important;
}

.orders-tab > .failed {
    background-color: #dfdfdf !important;
}
.orders-tab > .failed .active {
    border-bottom: 3px solid #b4b4b4 !important;
}
